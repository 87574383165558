import * as React from 'react'
import { Container } from '../components/UI/Container'
import { DefaultLayout } from '../layouts/DefaultLayout'
import styled from 'styled-components'
import { typoHeadingXl, typoParagraphIntro } from '../lib/styles/Typography'
import { Button } from '../components/UI/Button'
import { useTranslationString } from '../lib/context/i18n'
import CustomLink from '../components/CustomLink'

const Styled = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;

    ${Container} {
      width: 100%;
    }
  `,
  Inner: styled.div`
    padding-top: 8rem;
    text-align: center;
  `,
  Emoji: styled.p`
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 0.35rem;
  `,
  Headline: styled.h1`
    ${typoHeadingXl}
  `,
  Text: styled.p`
    ${typoParagraphIntro}
    margin-top: 1.5rem;
  `,
  ButtonWrapper: styled.div`
    align-items: center;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    margin-top: 3rem;
  `,
}

const Content = () => (
  <Styled.Wrapper>
    <Container>
      <Styled.Inner>
        <Styled.Emoji>😵‍💫</Styled.Emoji>
        <Styled.Headline>{useTranslationString('404_TITLE')}</Styled.Headline>
        <Styled.Text>{useTranslationString('404_DESCRIPTION')}</Styled.Text>
        <Styled.ButtonWrapper>
          <Button as={CustomLink} to="/" $ignoreTheme>
            {useTranslationString('404_RETURN_TO_HOME')}
          </Button>
        </Styled.ButtonWrapper>
      </Styled.Inner>
    </Container>
  </Styled.Wrapper>
)

const NotFoundPage = () => {
  return (
    <DefaultLayout>
      <Content />
    </DefaultLayout>
  )
}

export default NotFoundPage
